<template>
  <main>
    <core-unity-faq/>
    <core-unity-partners/>
  </main>
</template>

<script>
import CoreUnityFaq from "@/components/questions/FAQ";
import CoreUnityPartners from "@/components/common/Partners";
import CoreUnityStripe from "@/components/common/Stripe";
export default {
  name: "core-unity-questions",
  components: {CoreUnityStripe, CoreUnityPartners, CoreUnityFaq}
}
</script>

<style scoped>

</style>