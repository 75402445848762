<template>
  <div>
    <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="v => loading = v"
        :customerEmail="payload.email"
    />
    <button class="btn-booking-confirmation rounded-pill border-0 d-block mx-auto text-center d-flex align-items-center justify-content-center"
        @click="submit">Book your pitch</button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  name: "core-unity-stripe",
  components: {
    StripeCheckout,
  },
  props : {
    price : {
      type : String,
      required : true
    },
    payload : {
      type : Object,
      required : true
    },
  },
  mounted() {
    this.$store.commit("SetPayload", this.payload)
  },
  data () {
    return {
      publishableKey : process.env.VUE_APP_PUBLISH_KEY,
      loading: false,
      lineItems: [
        {
          price: this.price,
          quantity: 1,
        },
      ],
      successURL: `${window.location.origin}/success`,
      cancelURL: `${window.location.origin}/`,
    };
  },
  methods: {
    submit () {
      this.$emit('checkForm')
      this.$refs.checkoutRef.redirectToCheckout();
    }
  },
}
</script>

<style scoped>
.stipe {
  margin-top: 150px;
}
</style>
