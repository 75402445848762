<template>
  <section id="sponsor" class="">
    <div class="bg-cont">
      <div class="row overlay-bg">
        <div class="col-lg-10 col-md-10 col-sm-12 text-center my-5 mx-auto">
          <h2>The COREUNITY Festival takes over Dubai’s adventure capital to deliver 3 incredible days of music, movement & wellness in Hatta.</h2>
          <h4>Tickets starting from AED 199</h4>
          <div class="text-center"> <a href="https://my.coredirection.com/booking/3299?lat=24.806584&lng=56.12557" class="btn-get-started scrollto" target="_blank">Get Tickets</a></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-sponsor"
}
</script>

<style scoped>

</style>