import VueRouter from "vue-router";
import Home from "@/views/home";
import Booking from "@/views/booking";
import Questions from "@/views/questions";
import BookTent from "@/views/book-tent";
import Success from "@/views/success";
const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/book-your-pitch',
        name: 'Booking',
        component: Booking
    },
    {
        path: '/faq',
        name: 'Questions',
        component: Questions
    },
    {
        path: '/',
        name: 'BookTent',
        component: BookTent
    },
    {
        path: '/success',
        name: 'Success',
        component: Success
    },
]
const router = new VueRouter({
    mode: "history",
    routes,
});
export default router;
