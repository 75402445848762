<template>
  <div>
    <!-- ======= Morning Section ======= -->
    <section id="unity-section" class="section unity-section shadow_2 gradientone">
      <div class="container">
        <div class="title">
          <h2>Morning Sessions</h2>
        </div>
        <div class="row media-row">
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img  src="../../../assets/img/mmed2-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/mmed2-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Yoga.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Sunrise Yoga</h3>
            </div>
            <p>
              In the yogic discipline, the morning is considered as a “divine time” and when an individual's spiritual
              energy is at its peak. Clarity, creativity, and peace of mind to start your day.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/mmed.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/mmed.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Meditation.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Mountain Top Meditation</h3>
            </div>
            <p>Meditation is a practice of focusing the mind on a particular object, thought, or activity – to train
              attention and awareness, and achieve a mentally clear and emotionally calm and stable state.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/hiking.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/hiking.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Hiking.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Trail Hikes & Runs</h3>
            </div>
            <p>Surrounded by the Hajar Mountains, Hatta is covered in undulating peaks and troughs perfect for hiking.
              You can trek along the winding wadis at the foothills of the mountains, near the border with Oman.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Morning Section -->

    <!-- ======= Afternoon Section ======= -->
    <section id="unity-section" class="section unity-section gradienttwo">
      <div class="container">
        <div class="title">
          <h2>Afternoon Sessions</h2>
        </div>
        <div class="row media-row">
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/mountainbiking.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/mountainbiking.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Mountainbiking.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Mountain Biking</h3>
            </div>
            <p>Hatta Mountain Biking at Hatta Wadi Hub is the regions newest and most exciting opportunity for
              enthusiasts looking for both challenging and inspiring rides across Dubai’s largest and most dynamic
              national park. </p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/kayak.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/kayak.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/rowing.svg" alt="" class="svg me-3" width="40px">
              <h3 class="m-0">Kayaking</h3>
            </div>
            <p>Kayaking on the lake formed by the Hatta Dam is easily accessible from the Sedr Trailers Resort. Other
              water activties are also available to enjoy such as pedalo's and water bikes.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/kids-copy.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/kids-copy.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Kids Fitness.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Kids Activities</h3>
            </div>
            <p>Leave your little ones in our kids activites for some fun, fitness inspired play time. They'll make
              friends, learn the importance of team work and most importantly... be out of your hair for a few hours!
            </p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/hula-hoop-02.png"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/hula-hoop-02.png"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/hulahoop-05.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Hula Hooping</h3>
            </div>
            <p>The newest craze for energetic mindsets, transforming the game into something spectacular as you bump
              into your friends and roll around with laughter.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/ob.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/ob.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/course.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Obstacle Course</h3>
            </div>
            <p>Archery, a favorite sport of the UAE, can be enjoyed at a specially built zone featuring five lanes and a
              target distance of 10-m.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/coreunity/CU_2.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/coreunity/CU_2.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/exsports.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Extreme Slide</h3>
            </div>
            <p>The most unique attraction to Hatta Wadi Hub. Unleash your adrenaline as you launch from multiple slides
              straight into a plunge pool allowing you to reach heights of 15 meters and speeds of 40-80kmph.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/arttherapy.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/arttherapy.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/arttherapy.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Art Therapy</h3>
            </div>
            <p>The newest craze for energetic mindsets, transforming the game into something spectacular as you bump
              into your friends and roll around with laughter.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/journal.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/journal.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/journal.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Journaling</h3>
            </div>
            <p>Archery, a favorite sport of the UAE, can be enjoyed at a specially built zone featuring five lanes and a
              target distance of 10-m.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/handpan.png"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="card-img-bg">
              <img src="../../../assets/img/handpan.png"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" width="100%" class="img-fluid">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/handpan-08.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Handpan Workshops</h3>
            </div>
            <p>The most unique attraction to Hatta Wadi Hub. Unleash your adrenaline as you launch from multiple slides
              straight into a plunge pool allowing you to reach heights of 15 meters and speeds of 40-80kmph.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Afternoon Section -->

    <!-- ======= Evening Section ======= -->
    <section id="unity-section" class="section unity-section gradientthree">
      <div class="container">
        <div class="title">
          <h2>Evening Sessions</h2>
        </div>
        <div class="row media-row">
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/sunsetyoga.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/sunsetyoga.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Yoga.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Sunset Yoga</h3>
            </div>
            <p>In the yogic discipline, the morning is considered as a “divine time” and when an individual's spiritual
              energy is at its peak. Clarity, creativity, and peace of mind to start your day.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/livemusic-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/livemusic-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/livemusic.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Performing Artists</h3>
            </div>
            <p>Both DJ's and Musicians will be taking to the Coreunity stage, in association with Tunes DXB, to keep you
              singing & dancing all day and night.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/drum.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/drum.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/djembe-09.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Drum Circle</h3>
            </div>
            <p>Both DJ's and Musicians will be taking to the Coreunity stage, in association with Tunes DXB, to keep you
              singing & dancing all day and night.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/zumba.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/zumba.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/Zumba.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Dance Workouts</h3>
            </div>
            <p>Get fit, stay fit and burn calories at the best Zumba Class in the UAE! The classes move between high-
              and low-intensity dance moves designed to get your heart rate up and boost cardio endurance.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/dance-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/dance-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/ExcercisetoMusic.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Ecstatic Dance</h3>
            </div>
            <p>A form of dance in which the dancers, sometimes without the need to follow specific steps, abandon
              themselves to the rhythm and move freely as the music takes them, leading to trance and a feeling of
              ecstasy.</p>
          </div>
          <div class="col-lg-4 align-items-center my-auto content box py-5 mt-0">
            <div class="card-img-box">
              <img src="../../../assets/img/djhatta-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="card-img-bg" width="100%">
              <img src="../../../assets/img/djhatta-01.jpg"
                   sizes="(min-width: 1400px) 406px, (min-width: 1200px) 346px, (min-width: 1000px) 286px, (min-width: 780px) 686px, (min-width: 580px) 506px, calc(100vw - 34px)"
                   alt="" class="img-fluid" width="100%">
            </div>
            <div class="d-flex align-items-center my-3">
              <img src="../../../assets/img/icons/dj-04.svg" alt="" class="svg me-3" width="50px">
              <h3 class="m-0">Live DJs</h3>
            </div>
            <p>Both DJ's and Musicians will be taking to the Coreunity stage, in association with Tunes DXB, to keep you
              singing & dancing all day and night.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Evening Section -->
  </div>
</template>

<script>
export default {
  name: "core-unity-sessions"
}
</script>

<style scoped>

</style>