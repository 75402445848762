<template>
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="social d-flex align-items-center justify-content-between">
        <ul role="list" class="social-list d-flex align-items-center">
          <li class="social-item"> <a href="https://www.facebook.com/coredirection" target="_blank"
                                      class="social-link">
            <svg width="29" height="29" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M59 29.5C59 13.2059 45.7941 0 29.5 0C13.2059 0 0 13.2059 0 29.5C0 45.7941 13.2059 59 29.5 59C29.6729 59 29.8457 59 30.0186 58.9885V36.0338H23.6807V28.6473H30.0186V23.2082C30.0186 16.9049 33.8674 13.4709 39.4908 13.4709C42.1873 13.4709 44.5035 13.6668 45.1719 13.759V20.3504H41.3C38.2463 20.3504 37.6471 21.8023 37.6471 23.9342V28.6357H44.9645L44.008 36.0223H37.6471V57.8592C49.9771 54.3215 59 42.9709 59 29.5Z"
                  fill="#fff" />
            </svg>
          </a> </li>
          <li class="social-item"> <a href="https://www.instagram.com/coredirection/" target="_blank"
                                      class="social-link">
            <svg width="29" height="29" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M35.1445 29.5C35.1445 32.6185 32.6166 35.1465 29.498 35.1465C26.3795 35.1465 23.8516 32.6185 23.8516 29.5C23.8516 26.3815 26.3795 23.8535 29.498 23.8535C32.6166 23.8535 35.1445 26.3815 35.1445 29.5Z"
                  fill="#fff" />
              <path
                  d="M42.706 19.5096C42.4345 18.7741 42.0015 18.1083 41.4388 17.5619C40.8924 16.9992 40.2271 16.5662 39.4911 16.2947C38.8942 16.0629 37.9976 15.787 36.346 15.7118C34.5594 15.6303 34.0238 15.6128 29.5008 15.6128C24.9774 15.6128 24.4418 15.6299 22.6556 15.7114C21.0041 15.787 20.107 16.0629 19.5105 16.2947C18.7746 16.5662 18.1088 16.9992 17.5628 17.5619C17.0001 18.1083 16.5671 18.7736 16.2952 19.5096C16.0634 20.1065 15.7875 21.0036 15.7123 22.6551C15.6308 24.4413 15.6133 24.9769 15.6133 29.5003C15.6133 34.0233 15.6308 34.5589 15.7123 36.3455C15.7875 37.9971 16.0634 38.8937 16.2952 39.4906C16.5671 40.2266 16.9997 40.8919 17.5624 41.4383C18.1088 42.001 18.7741 42.434 19.5101 42.7055C20.107 42.9377 21.0041 43.2137 22.6556 43.2888C24.4418 43.3703 24.977 43.3874 29.5004 43.3874C34.0242 43.3874 34.5599 43.3703 36.3456 43.2888C37.9971 43.2137 38.8942 42.9377 39.4911 42.7055C40.9684 42.1356 42.1361 40.968 42.706 39.4906C42.9378 38.8937 43.2137 37.9971 43.2893 36.3455C43.3708 34.5589 43.3879 34.0233 43.3879 29.5003C43.3879 24.9769 43.3708 24.4413 43.2893 22.6551C43.2142 21.0036 42.9382 20.1065 42.706 19.5096ZM29.5008 38.1983C24.6965 38.1983 20.802 34.3042 20.802 29.4999C20.802 24.6956 24.6965 20.8015 29.5008 20.8015C34.3047 20.8015 38.1992 24.6956 38.1992 29.4999C38.1992 34.3042 34.3047 38.1983 29.5008 38.1983ZM38.5431 22.4904C37.4205 22.4904 36.5103 21.5802 36.5103 20.4576C36.5103 19.335 37.4205 18.4248 38.5431 18.4248C39.6658 18.4248 40.5759 19.335 40.5759 20.4576C40.5755 21.5802 39.6658 22.4904 38.5431 22.4904Z"
                  fill="#fff" />
              <path
                  d="M29.5 0C13.2101 0 0 13.2101 0 29.5C0 45.7899 13.2101 59 29.5 59C45.7899 59 59 45.7899 59 29.5C59 13.2101 45.7899 0 29.5 0ZM46.3373 36.4838C46.2553 38.2871 45.9686 39.5182 45.55 40.5958C44.67 42.8712 42.8712 44.67 40.5958 45.55C39.5186 45.9686 38.2871 46.2549 36.4843 46.3373C34.6779 46.4196 34.1008 46.4395 29.5005 46.4395C24.8996 46.4395 24.323 46.4196 22.5162 46.3373C20.7134 46.2549 19.4818 45.9686 18.4046 45.55C17.2739 45.1246 16.2503 44.458 15.404 43.596C14.5425 42.7502 13.8758 41.7261 13.4505 40.5958C13.0318 39.5186 12.7451 38.2871 12.6632 36.4843C12.5799 34.6774 12.5605 34.1004 12.5605 29.5C12.5605 24.8996 12.5799 24.3226 12.6627 22.5162C12.7447 20.7129 13.0309 19.4818 13.4496 18.4042C13.8749 17.2739 14.542 16.2498 15.404 15.404C16.2498 14.542 17.2739 13.8754 18.4042 13.45C19.4818 13.0314 20.7129 12.7451 22.5162 12.6627C24.3226 12.5804 24.8996 12.5605 29.5 12.5605C34.1004 12.5605 34.6774 12.5804 36.4838 12.6632C38.2871 12.7451 39.5182 13.0314 40.5958 13.4496C41.7261 13.8749 42.7502 14.542 43.5964 15.404C44.458 16.2503 45.1251 17.2739 45.55 18.4042C45.9691 19.4818 46.2553 20.7129 46.3377 22.5162C46.4201 24.3226 46.4395 24.8996 46.4395 29.5C46.4395 34.1004 46.4201 34.6774 46.3373 36.4838Z"
                  fill="#fff" />
            </svg>
          </a> </li>
          <li class="social-item"> <a href="https://www.youtube.com/channel/UC_4V4td655WQtkU6Joq301Q" target="_blank"
                                      class="social-link">
            <svg width="29" height="29" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.8242 35.0266L35.4202 29.4999L25.8242 23.9731V35.0266Z" fill="#fff" />
              <path
                  d="M29.5 0C13.2101 0 0 13.2101 0 29.5C0 45.7899 13.2101 59 29.5 59C45.7899 59 59 45.7899 59 29.5C59 13.2101 45.7899 0 29.5 0ZM47.933 29.5302C47.933 29.5302 47.933 35.5129 47.1741 38.3978C46.7487 39.9769 45.5036 41.2219 43.9246 41.6469C41.0396 42.4062 29.5 42.4062 29.5 42.4062C29.5 42.4062 17.9905 42.4062 15.0754 41.6167C13.4964 41.1918 12.2513 39.9463 11.8259 38.3672C11.0666 35.5129 11.0666 29.5 11.0666 29.5C11.0666 29.5 11.0666 23.5177 11.8259 20.6328C12.2509 19.0537 13.5265 17.7781 15.0754 17.3531C17.9604 16.5938 29.5 16.5938 29.5 16.5938C29.5 16.5938 41.0396 16.5938 43.9246 17.3833C45.5036 17.8082 46.7487 19.0537 47.1741 20.6328C47.9636 23.5177 47.933 29.5302 47.933 29.5302Z"
                  fill="#fff" />
            </svg>
          </a> </li>
        </ul>
      </div>


      <a href="/" class="logo">
        <img src="../assets/img/coreunitylogo-01.svg" width="275" height="44" alt="Core Direction Logo" class="img-fluid">
      </a>


      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="/">Home</a></li>
          <li><a class="nav-link scrollto" href="/book-your-pitch">Book Your Tent</a></li>
          <li><a class="nav-link scrollto" href="/faq">FAQ</a></li>
          <li><a class="nav-link scrollto" href="https://my.coredirection.com/contact">Contact</a></li>
          <li><a href="https://my.coredirection.com/booking/3299?lat=24.806584&lng=56.12557" class="getstarted scrollto"
                 target="_blank">Get Tickets</a></li>
        </ul>
        <i class="ri-menu-line mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
      <a href="https://my.coredirection.com/booking/3299?lat=24.806584&lng=56.12557"
         class="get-started-btn scrollto hide" style="margin-left: 1rem;" target="_blank">Get Tickets</a>
    </div>
  </header>
</template>

<script>
export default {
  name: "core-unity-navbar"
}
</script>

<style scoped>

</style>