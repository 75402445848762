<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container position-relative">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10 text-center pt-5">
          <h1>Community | Music | Movement</h1>
          <h3>13-15 January 2023 | Hatta Wadi Hub</h3>
          <div class="text-center">
            <a href="https://my.coredirection.com/booking/3299?lat=24.806584&lng=56.12557"
               class="btn-get-started scrollto" target="_blank">
              Get Tickets
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-9 mx-auto">
        <div class="row justify-content-center pt-4">
          <div class="col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center">
            <img loading="lazy"
                 src="../../assets/img/clients/svg/dubaishoppingfestival.svg" width="200" height="auto"
                 class="img-fluid"
                 alt="Dubai Shopping Festival">

            <img src="../../assets/img/clients/svg/coredirecton.svg" width="200" height="auto"
                 alt="Core Direction Logo" class="img-fluid">
          </div>
          <div class="col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center">
            <img loading="lazy" src="../../assets/img/clients/svg/hatta.svg" width="200" height="auto" class="img-fluid"
                 alt="Hatta Wadi Hub">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'core-unity-hero',
};
</script>

<style scoped>

</style>
