<template>
  <section id="lineup" class="my-0 grad_bg_2 shadow_2">
    <div class="container my-5">
      <h2 class="slider-title">Other Accommodation</h2>
      <div class="content slick-slider my-5">
        <a href="https://www.visithatta.com/en/stay/damani-lodges-resort"><div class="img-box">
          <img src="../../assets/img/domani.png" width="225px">
          <p class="user-name">Damani Lodges</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/hatta-dome-park"><div class="img-box">
          <img src="../../assets/img/hattadome.png" width="225px">
          <p class="user-name">Hatta Dome</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/hatta-caravan-park"><div class="img-box">
          <img src="../../assets/img/caravanpark.png" width="225px">
          <p class="user-name">Caravan Park</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/sedr-trailers-resort"><div class="img-box">
          <img src="../../assets/img/sedrtrailer.png" width="225px">
          <p class="user-name">Sedr Trailer</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/damani-lodges-resort"><div class="img-box">
          <img src="../../assets/img/domani.png" width="225px">
          <p class="user-name">Damani Lodges</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/hatta-dome-park"><div class="img-box">
          <img src="../../assets/img/hattadome.png" width="225px">
          <p class="user-name">Hatta Dome</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/hatta-caravan-park"><div class="img-box">
          <img src="../../assets/img/caravanpark.png" width="225px">
          <p class="user-name">Caravan Park</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
        <a href="https://www.visithatta.com/en/stay/sedr-trailers-resort"><div class="img-box">
          <img src="../../assets/img/sedrtrailer.png" width="225px">
          <p class="user-name">Sedr Trailer</p>
          <p class="user-handle">From AED 4000 Per Night</p>
        </div></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-accomodation"
}
</script>

<style scoped>

</style>