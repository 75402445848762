import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import VueRouter from "vue-router";
import store from "./store"
import jQuery from 'jquery'
global.jQuery = jQuery

Vue.config.productionTip = false
Vue.use(VueRouter, { history: true });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
