<template>
  <div>
    <core-unity-hero/>
    <main id="main">
      <core-unity-info/>
      <core-unity-sponsor/>
      <core-unity-sessions/>
      <core-unity-wellness-talent/>
      <core-unity-camping/>
      <core-unity-musicians/>
      <core-unity-location/>
    </main>
    <core-unity-partners/>
  </div>
</template>

<script>
import CoreUnityHero from "@/components/home/Hero";
import CoreUnityInfo from "@/components/home/main/Info";
import CoreUnitySponsor from "@/components/home/main/Sponsor";
import CoreUnitySessions from "@/components/home/main/Sessions";
import CoreUnityWellnessTalent from "@/components/home/main/WellnessTalent";
import CoreUnityCamping from "@/components/home/main/Camping";
import CoreUnityMusicians from "@/components/home/main/Musicians";
import CoreUnityLocation from "@/components/common/Location";
import CoreUnityPartners from "@/components/common/Partners";
export default {
  name: "core-unity-home",
  components : {
    CoreUnityPartners,
    CoreUnityLocation,
    CoreUnityMusicians,
    CoreUnityCamping,
    CoreUnityWellnessTalent,
    CoreUnitySessions,
    CoreUnitySponsor,
    CoreUnityInfo,
    CoreUnityHero,
  }
}
</script>

<style scoped>

</style>