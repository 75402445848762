<template>
  <section id="lineup" class="mt-0 mb-0 grad_bg_1">
    <div class="container">
      <h2 class="slider-title">Wellness Talent</h2>
      <div class="content slick-slider">
        <div class="img-box">
          <img src="../../../assets/img/manish.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/kino.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/vanessa.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/jed.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/clark.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/manish.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/kino.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/vanessa.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/jed.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/clark.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Person Name</p>
          <p class="user-handle">@instaHandle</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-wellness-talent"
}
</script>

<style scoped>

</style>