<template>
  <div id="main">
    <!-- camp section -->
    <div id="camp">
      <div class="container">
        <div id="coreunity-confirmation-modal" class="custom-modal m-auto">
          <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
          <ul style="list-style: none">
            <li style="color: red" v-for="error in errors">{{ error }}</li>
          </ul>
          </p>
          <div class="modal-center">
            <div class="modal-outer-box mx-auto">
              <div class="modal-inner-box">
                <div class="modal-body px-5 pb-3 mb-5">
                  <div class="form-container mx-auto">
                    <h3 class="modal-title text-center">Enter Your Email</h3>
                    <input v-model="payload.email" type="email" class="form-control border-0 border-bottom rounded-0" placeholder="Enter Your Email">
                  </div>
                </div>
                <div class="modal-body px-5">
                  <div class="form-container mx-auto">
                    <h3 class="modal-title text-center">Choose Your Camping Zone</h3>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="inputGroup">
                          <input id="radio1" name="radio" type="radio" value="morning" v-model="payload.camping_zone" />
                          <label for="radio1" class="flex items-center">
                            <img src="../../assets/img/icons/coreunity-01.svg" width="100" alt=""
                                 class="coreunity-img">
                            <div class="coreunity-content-box">
                              <p class="coreunity-title">Early Risers</p>
                              <p class="coreunity-desc">For those who like to get an early night & an early start</p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="inputGroup">
                          <input id="radio2" name="radio" type="radio" value="night" v-model="payload.camping_zone" />
                          <label for="radio2" class="flex items-center">
                            <img src="../../assets/img/icons/coreunity-03.svg" width="100" alt=""
                                 class="coreunity-img">
                            <div class="coreunity-content-box">
                              <p class="coreunity-title">Night Owls</p>
                              <p class="coreunity-desc">For those who are up late into the night!</p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="inputGroup">
                          <input id="radio3" name="radio" type="radio" value="family" v-model="payload.camping_zone" />
                          <label for="radio3" class="flex items-center">
                            <img src="../../assets/img/icons/coreunity-02.svg" width="100" alt=""
                                 class="coreunity-img">
                            <div class="coreunity-content-box">
                              <p class="coreunity-title">Family Zone</p>
                              <p class="coreunity-desc">Child friendly zone for families.</p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <a href="#tent"
                       class="btn-booking-confirmation btn-next-section rounded-pill border-0 d-block mx-auto text-center d-flex align-items-center justify-content-center">Next</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- tent secton -->
    <div id="tent">
      <div class="container">
        <div id="coreunity-confirmation-modal" class="custom-modal m-auto">
          <div class="modal-center">
            <div class="modal-outer-box mx-auto">
              <div class="modal-inner-box">
                <div class="modal-body px-5">
                  <div class="form-container mx-auto">
                    <h3 class="modal-title text-center">Choose Your Camping Package</h3>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="inputGroup">
                          <input id="radio4" name="radio1" type="radio" :value="plan1" v-model="price_id" />
                          <label for="radio4" class="flex items-center">
                            <img src="../../assets/img/icons/coreunity-04.svg" width="100" alt=""
                                 class="coreunity-img">
                            <div class="coreunity-content-box">
                              <p class="coreunity-title">Package 1: AED 649</p>
                              <p class="coreunity-desc">2 Man Tent, Inflatable Mattress & Light. Pre-pitched by our team,
                                with serviced ablutions.</p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="inputGroup">
                          <input id="radio5" name="radio1" type="radio" :value="plan2" v-model="price_id" />
                          <label for="radio5" class="flex items-center">
                            <img src="../../assets/img/icons/coreunity-04.svg" width="100" alt=""
                                 class="coreunity-img">
                            <div class="coreunity-content-box">
                              <p class="coreunity-title">Package 2: AED 99</p>
                              <p class="coreunity-desc">Tent plot (no tent) with serviced ablutions.</p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <core-unity-stripe @checkForm="checkForm" v-if="showBook" :price="price_id" :payload="payload" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoreUnityStripe from "@/components/common/Stripe";
export default {
  name: "core-unity-book-tent",
  components: {CoreUnityStripe},
  data () {
    return {
      plan1  : process.env.VUE_APP_PLAN_1,
      plan2  : process.env.VUE_APP_PLAN_2,
      price_id : '',
      showBook : true,
      payload : {
        email : "",
        amount : 0,
        currency: "aed",
        payment_type: "event-core-unity-festival",
        camping_zone: ""
      },
      errors : []
    }
  },
  watch : {
    price_id () {
      this.payload.amount = this.price_id === this.plan1 ? 649 : 99
      this.showBook = false
      this.$nextTick(() => {
        this.showBook = true;
      });
    }
  },
  methods : {
    checkForm() {
      console.log('inside')
      this.errors = [];

      if (!this.payload.email) {
        this.errors.push("Email required.");
      }
      if (!this.payload.camping_zone) {
        this.errors.push("Camping Zone required.");
      }
      if (!this.payload.amount) {
        this.errors.push("Package required.");
      }
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>

</style>
