<template>
  <section id="hero-pitch" class="d-flex align-items-center shadow_1">
    <div class="container position-relative">
      <div class="row position-relative justify-content-center align-items-center">
        <div class="col-xl-7 col-lg-8 col-md-12 col-sm-12">
          <div class="content">
            <h1>Sleep Under The Stars</h1>
            <h2 style="margin-bottom:20px;">Book your tent for Coreunity Festival!</h2>
            <p style="margin-left:3rem;"><b>Step 1:</b> Choose your camping zone.</p>
            <p style="margin-left:3rem;"><b>Step 2:</b> Select your Tent, AED 449 Per Pitch.</p>
            <p style="margin-left:3rem;"><b>Step 3:</b> Arrive to a pitched tent, Mattress & Light on-site!</p>

            <div class="hero-btns">
              <a href="#" class="btn-get-started mb-5" @click="$router.push('/book-tent')">Book your pitch</a> </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-book-your-pitch"
}
</script>

<style scoped>

</style>