<template>
  <section id="faq" class="faq mt-5">
    <div class="container" data-aos="fade-up">

      <div class="section-title mt-5">
        <h2 style="color:#0D0D0D;">Frequently Asked Questions</h2>
      </div>

      <div class="faq-list my-5">
        <ul>
          <li data-aos="fade-up" data-aos-delay="100">
            <i class="ri ri-question-line icon-help"></i> <a data-bs-toggle="collapse" class="collapse"
                                                             data-bs-target="#faq-list-1"><strong>Where can I find the Core Direction User Guides?</strong> <i
              class="ri ri-arrow-down-line icon-show"></i><i class="ri ri-arrow-up-line icon-close"></i></a>
            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
              <p>
                Our full user guide is available here <a class="p-0 d-inline-block" href="https://coredirection.com/user-guides">https://coredirection.com/user-guides</a>. You can find all the answers to your questions here and become a Core Direction Guru!
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <i class="ri ri-question-line icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2"
                                                             class="collapsed"><strong>How Do I earn Core Points?</strong> <i
              class="ri ri-arrow-down-line icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
              <p>
                There are 5 different ways to earn Core Points on our platform, from manually entering data, watching wellness content or tracking your steps & heart rate data. <a class="p-0 d-inline-block" href="http://www.coredirection.com/guides/core_points">Find out more here</a>
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <i class="ri ri-question-line icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3"
                                                             class="collapsed"><strong>How do I use the Core Direction App?</strong> <i
              class="ri ri-arrow-down-line icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
              <p>
                The Core Direction App is only used for tracking your Steps & Heart Rate and earning Core Points. All other functionality of our platform is available here - <a class="p-0 d-inline-block" href="https://my.coredirection.com">https://my.coredirection.com</a>. To <a class="p-0 d-inline-block" href="http://www.coredirection.com/guides/app">download the app click here</a>.
              </p>
            </div>
          </li>
          <li data-aos="fade-up" data-aos-delay="300">
            <i class="ri ri-question-line icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4"
                                                             class="collapsed"><strong>How can I connect a wearable?</strong> <i
              class="ri ri-arrow-down-line icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
              <p>
                Connect your wearable to the Core Direction Platform to track your Steps & Heart Rate Activity! <br>
                <a class="p-0 d-inline-block" href="http://coredirection.com/guides/ios-guide">Click here for the iOS Guide</a><br>
                <a class="p-0 d-inline-block" href="http://coredirection.com/guides/android-guide">Click here for the Android Guide</a>
              </p>
            </div>
          </li>
          <li data-aos="fade-up" data-aos-delay="300">
            <i class="ri ri-question-line icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5"
                                                             class="collapsed"><strong>How do I add an activity to My Calendar?</strong> <i
              class="ri ri-arrow-down-line icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
              <p>
                Manually adding an activity to your calendar a great way to keep track of your workout schedule & invite your friends to join you so you can #InspireMovement. <a class="p-0 d-inline-block" href="http://coredirection.com/guides/add-activity">Click here for a full guide</a>.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="400">
            <i class="ri ri-question-line icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-6"
                                                             class="collapsed"><strong>How do I book an Activity?</strong> <i
              class="ri ri-arrow-down-line icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-6" class="collapse" data-bs-parent=".faq-list">
              <p>
                There are hundred of fitness business & activities available on our platform, <a class="p-0 d-inline-block" href="http://coredirection.com/guides/book-activity">Click here</a> for a full guide on how to book them for yourself, for friends and for your family.
              </p>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-faq"
}
</script>

<style scoped>

</style>