<template>
  <div id="app">
    <core-unity-navbar/>
    <router-view/>
    <core-unity-footer/>
  </div>
</template>

<script>
import CoreUnityNavbar from "@/components/Navbar";
import CoreUnityFooter from "@/components/Footer";
import $ from 'jquery';
import "./assets/vendor/jQuery/jquery-3.6.0.min.js"
import "./assets/vendor/slick/slick.min.js"
import "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
import "./assets/vendor/glightbox/js/glightbox.min.js"
import "./assets/js/main.v5.js"
export default {
  name: 'App',
  components: {
    CoreUnityFooter,
    CoreUnityNavbar,
  },
  mounted() {
    $(document).ready(function () {
      $('.slick-slider').slick({
        dots: false,
        infinite: true,
        speed: 300,
        centerMode: true,
        variableWidth: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ],
        prevArrow: "<i class='ri-arrow-left-s-line'></i>",
        nextArrow: "<i class='ri-arrow-right-s-line'></i>"
      });
    });
  }
}
</script>

<style>
@import "assets/vendor/slick/slick.min.css";
@import "assets/vendor/bootstrap/css/bootstrap.min.css";
@import "assets/vendor/glightbox/css/glightbox.min.css";
@import "assets/vendor/remixicon/remixicon.css";
@import "assets/css/style.v1.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
