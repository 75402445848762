<template>
  <div id="page-not-found">
    <div class="container d-flex align-items-center justify-content-center">
      <div class="page-not-found-content-box">
        <p class="page-not-found-subtitle text-center">Payment Successful</p>
        <p class="page-not-found-desc text-center">Your Payment was successful, please check your email for your payment receipt. If you have not received your receipt please contact <a href="mailto:accounts@coredirection.com">accounts@coredirection.com</a></p>
        <a href="/" class="btn-page-not-found rounded-pill">Home</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "success",
  mounted () {
    let $this = this
    axios.post(process.env.VUE_APP_END_POINT ,$this.$store.state.payload)
        .then(function (response) {
          console.log(response, $this.$store.state.payload);
          $this.$store.commit("SetPayload", {})
        })
        .catch(function (error) {
          console.log(error);
        });
  }
}
</script>

<style scoped>

</style>
