<template>
  <div>
    <core-unity-book-your-pitch/>
    <main id="main">
      <core-unity-accomodation/>
      <core-unity-location/>
    </main>
    <core-unity-partners/>
  </div>
</template>

<script>
import CoreUnityLocation from "@/components/common/Location";
import CoreUnityPartners from "@/components/common/Partners";
import CoreUnityBookYourPitch from "@/components/booking/BookYourPitch";
import CoreUnityAccomodation from "@/components/booking/Accomodation";
export default {
  name: "core-unity-booking",
  components: {CoreUnityAccomodation, CoreUnityBookYourPitch, CoreUnityPartners, CoreUnityLocation}
}
</script>

<style scoped>

</style>