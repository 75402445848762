<template>
  <section id="location" class="section my-0">
    <div class="container py-5">
      <div class="row content">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14610.362555098178!2d56.15573964255095!3d24.819509016791574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef51f194d102e29%3A0x9c1607f0d61e3411!2sHatta%20Wadi%20Hub%20by%20Dubai%20Holding!5e0!3m2!1sen!2sae!4v1660641129116!5m2!1sen!2sae"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-location"
}
</script>

<style scoped>

</style>