<template>
  <section id="lineup" class="my-0 grad_bg_2">
    <div class="container">
      <h2 class="slider-title">DJs & Musicians</h2>
      <div class="content slick-slider">
        <div class="img-box">
          <img src="../../../assets/img/charl.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Charl Chaka</p>
          <p class="user-handle"><a class="user-handle" href="https://www.instagram.com/charlchaka/">@charlchaka</a>
          </p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/david.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">David Craig</p>
          <p class="user-handle"><a class="user-handle"
                                    href="https://www.instagram.com/djdavidcraig/">@djdavidcraig</a></p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/dan.jpg"
               sizes="195px" width="225px">
          <p class="user-name">Dan Moores</p>
          <p class="user-handle"><a class="user-handle"
                                    href="https://www.instagram.com/dmooresmusic/">@dmooresmusic</a></p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/bruni.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Bruno Fave</p>
          <p class="user-handle"><a class="user-handle" href="https://www.instagram.com/brufave/">@brufave</a></p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/charl.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Charl Chaka</p>
          <p class="user-handle"><a class="user-handle" href="https://www.instagram.com/charlchaka/">@charlchaka</a>
          </p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/david.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">David Craig</p>
          <p class="user-handle"><a class="user-handle"
                                    href="https://www.instagram.com/djdavidcraig/">@djdavidcraig</a></p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/dan.jpg"
               sizes="195px" width="225px">
          <p class="user-name">Dan Moores</p>
          <p class="user-handle"><a class="user-handle"
                                    href="https://www.instagram.com/dmooresmusic/">@dmooresmusic</a></p>
        </div>
        <div class="img-box">
          <img src="../../../assets/img/bruni.jpeg"
               sizes="195px" width="225px">
          <p class="user-name">Bruno Fave</p>
          <p class="user-handle"><a class="user-handle" href="https://www.instagram.com/brufave/">@brufave</a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-musicians"
}
</script>

<style scoped>

</style>