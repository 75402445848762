<template>
  <section id="camping" class="section shadow_1">
    <div class="container">
      <div class="row content my-auto">
        <div class="col-lg-5"> <img loading="lazy" src="../../../assets/img/camp.jpg"
                                    sizes="(min-width: 1400px) 526px, (min-width: 1200px) 451px, (min-width: 1000px) 376px, (min-width: 780px) 550px, (min-width: 580px) 516px, calc(100vw - 24px)"
                                    width="550" height="300" class="img-fluid" alt="Camping">
        </div>
        <div class="col-lg-2 sm-camping-grid">
          <img loading="lazy" src="../../../assets/img/lodge.jpeg"
               sizes="(min-width: 1400px) 196px, (min-width: 1200px) 166px, (min-width: 1000px) 136px, (min-width: 780px) 232px, (min-width: 580px) 172px, calc(33.46vw - 8px)"
               width="550" height="300" class="img-fluid" alt="Camping">
          <img loading="lazy" src="../../../assets/img/vans.jpeg"
               sizes="(min-width: 1400px) 196px, (min-width: 1200px) 166px, (min-width: 1000px) 136px, (min-width: 780px) 232px, (min-width: 580px) 172px, calc(33.46vw - 8px)"
               width="550" height="300" class="img-fluid" alt="Camping">
          <img loading="lazy" src="../../../assets/img/domani.jpeg"
               sizes="(min-width: 1400px) 196px, (min-width: 1200px) 166px, (min-width: 1000px) 136px, (min-width: 780px) 232px, (min-width: 580px) 172px, calc(33.46vw - 8px)"
               width="550" height="300" class="img-fluid" alt="Camping">
        </div>
        <div class="col-lg-5 pt-3 pt-lg-0 my-auto content">
          <h3>Sleep Under the Stars</h3>
          <p>COREUNITY is the regions first ever camping style festival! For the brave, camp under the stars with the
            backdrop of the impressive Hatta Mountains or, if you want a little more comfort, choose some of Hatta
            Wadi Hub's many accomodation options.</p>
          <router-link class="btn-get-started" :to="{ name: 'Booking'}" target="_blank">
            Book your Tent
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-camping"
}
</script>

<style scoped>

</style>