<template>
  <core-unity-book-tent/>
</template>

<script>
import CoreUnityBookTent from "@/components/book-tent/BookTent";
export default {
  name: "book-tent",
  components: {CoreUnityBookTent}
}
</script>

<style scoped>

</style>