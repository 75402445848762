<template>
  <div class="screenshot-area-l-16" style="background:rgb(229,7,128);background: linear-gradient(90deg, rgba(229,7,128,1) 0%, rgba(158,24,127,1) 36%, rgba(81,42,126,1) 100%);;padding-top: 100px;padding-bottom: 100px;padding-top: 100px;padding-bottom: 100px;">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9 col-md-10">
          <div class="section-heading-10 text-center">
            <h2 style="color:#fff">Our Partners</h2>
          </div>
        </div>
      </div>
      <div class="partners row justify-content-center">
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img loading="lazy"
               src="../../assets/img/clients/svg/dubaishoppingfestival.svg" width="auto" height="auto"
               class="img-fluid" alt="Dubai Shopping Festival">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img loading="lazy"
               src="../../assets/img/clients/svg/tunesdxb.svg" width="auto" height="auto" class="img-fluid"
               alt="Tunes DXB">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img loading="lazy"
               src="../../assets/img/clients/svg/coredirecton.svg" width="auto" height="auto" class="img-fluid"
               alt="Core Direction">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img loading="lazy"
               src="../../assets/img/clients/svg/hatta.svg" width="auto" height="auto" class="img-fluid"
               alt="Hatta Wadi Hub">
        </div>
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img loading="lazy"
               src="../../assets/img/clients/svg/dubaisportscouncil.svg" width="auto" height="auto" class="img-fluid"
               alt="Dubai Sports Council">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "core-unity-partners"
}
</script>

<style scoped>

</style>