<template>
  <section id="info" class="">
    <div class="">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 img-cont">
          <div class="inner-box">
            <div class="img-box">
              <img src="../../../assets/img/camping-01.png" width="100%" alt="">
              <h2>Camping</h2>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 img-cont">
          <div class="inner-box">
            <div class="img-box">
              <img src="../../../assets/img/movement-02.jpg" width="100%" alt="">
              <h2>Movement</h2>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 img-cont">
          <div class="inner-box">
            <div class="img-box">
              <img src="../../../assets/img/djhatta-01.jpg" width="100%" alt="">
              <h2>Music</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "core-unity-info"
}
</script>

<style scoped>

</style>